<template>
  <div id="addMoDular">
    <div class="headerDetail">
      <ds-header :title="headerTitle"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" labelAlign="right" :labelCol="{ style: 'width: 240px' }" :rules="rules">
      <div style="margin-top: 20px">
        <div class="content">
            <a-form-model-item label="积分类型：" prop="integralType" :span="24">
                <a-radio-group name="radioGroup" v-model="form.integralType" :disabled='disabledInfo'>
                    <a-radio value="work"> 上班积分 </a-radio>
                    <a-radio value="work_over_time"> 加班积分 </a-radio>
                    <a-radio value="birth_over_time"> 生日积分 </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="积分规则编号：" prop="ruleNo" :span="24">
                <a-input
                    :disabled='disabledInfo'
                    class="indexClass"
                    placeholder="请输入（最多16个字数）"
                    v-model.trim="form.ruleNo"
                    @blur="onBlur"
                />
            </a-form-model-item>
            <a-form-model-item label="积分额度：" :span="24">
                <div>{{rule}}</div>
            </a-form-model-item>
            <a-form-model-item label="所属项目：" prop="projectId" :span="24">
                <a-select
                  :disabled='disabledInfo'
                  class="indexClass"
                  placeholder="请选择"
                  v-model="form.projectId"
                >
                  <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
                    {{ item.project_name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="企业类型：" prop="enterpriseType" :span="24">
                <a-select
                  :disabled='disabled'
                  class="indexClass"
                  placeholder="请选择"
                  v-model="form.enterpriseType"
                  mode="multiple"
                >
                  <a-select-option v-for="item in enterpriseTypeList" :key="item.id" :value="item.id">
                    {{ item.commonsValueName }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="适用商户：" :span="24">
              <div style="display: flex;align-items: center;">
                <a-upload
                  :show-upload-list="false"
                  name="filter"
                  accept=".xlsx"
                  :multiple="false"
                  action="/api/dscloud-appservice-management/web/work-integral/import-merchant"
                  :headers="upload.headers"
                  @change="handleChange"
                >
                  <a-button
                    type="primary"
                    class="uploadClass"
                    :disabled="disabled"
                  >
                    <a-icon type="upload" /> 点击上传
                  </a-button>

                </a-upload>
                <div style="padding-left: 6px;" @click="dowmModel">下载模版</div>
              </div>
              <div style="padding-top: 6px;">注：支持扩展名：.xlsx</div>
            </a-form-model-item>
            <a-form-model-item v-if="form.merchantList.length > 0" label="适用商户：" :span="24">
              <div style="display: flex;flex-wrap: wrap;">
                <div v-for="(item, index) in form.merchantList" :key="index">
                  <a-tag v-if="$route.query.type == 'view'">{{item.merchantId}}-{{item.merchantName}}</a-tag>
                  <a-tag v-else :key="item.merchantId" closable @close="tagRemove(index)">{{item.merchantId}}-{{item.merchantName}}</a-tag>
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="消息备注文案：" prop="messageRemark" :span="24">
                <a-input
                    :disabled='disabled'
                    class="indexClass"
                    placeholder="建议输入不超过15个字数"
                    v-model.trim="form.messageRemark"
                />
            </a-form-model-item>
            <a-form-model-item prop="remark" label="备注" :span="24">
                <a-input
                    :disabled='disabled'
                    class="indexClass"
                    placeholder="请输入（最多30个字数）"
                    v-model.trim="form.remark"
                />
            </a-form-model-item>
        </div>
      </div>
    </a-form-model>
    <div class="footer">
      <a-button key="submit" style="margin-right: 30px" type="primary" v-if="$route.query.type != 'view'" @click="handleOk">保存
      </a-button>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/pointsManagement";
import {getEnterpriseType} from "@/api/enterpriseServiceActivitiesManagement";
import {getEnterpriseTypes} from "@/api/common";
export default {
  components: {

  },
  data() {
    return {
      upload: {
        // 设置上传的请求头部
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user")).accessToken},
      },
      rules: {
        integralType: [{ required: true, message: "请选择积分类型", trigger: "change" }],
        ruleNo: [
          { required: true, message: "请输入积分规则编号", trigger: "blur" },
          { max: 16, message: "积分规则编号不能超过16字符", trigger: 'blur'}
        ],
        projectId: [{ required: true, message: "请选择所属项目", trigger: "blur" }],
        enterpriseType: [{ required: true, message: "请选择企业类型", trigger: "blur" }],
        messageRemark: [{ required: true, message: "请输入消息备注文案", trigger: "blur" }],
        remark: [
          { max: 16, message: "备注不能超过30字符", trigger: 'blur'}
        ],
      },
      form: {
        integralType: "work",
        ruleNo: "",
        projectId: undefined,
        enterpriseType: [],
        merchantList: [],
        messageRemark: '',
        remark: '',
      },
      projectList: [], // 项目列表
      enterpriseTypeList: [], // 企业类型
      rule: null,
      headerTitle: "新建积分",
      disabled: false,
      disabledInfo: false
    };
  },
  created() {
    if(this.$route.query.id) {
      this.getInfomation();
    }
    if(this.$route.query.type == 'update') {
      this.headerTitle = "修改积分";
      this.disabledInfo = true;
    }
    if(this.$route.query.type == 'view') {
      this.disabled = true;
      this.disabledInfo = true;
      this.headerTitle = "查看积分";
    }
    this.projectNameList();
    this.getEnterpriseTypeList();
  },
  methods: {

    async dowmModel () {
      const res = await api.pointsModel();
      const blob = new Blob([res.data]);
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = objectUrl;
      a.setAttribute("download", "积分模版.xlsx");
      a.click();
    },

    // 详情
    async getInfomation() {
      var data = {};
      data.id = this.$route.query.id;

      const res = await api.pointsInfo(data);
      this.form.integralType = res.data.integralType;
      this.form.ruleNo = res.data.ruleNo;
      this.form.projectId = res.data.projectId;
      this.form.enterpriseType = res.data.enterpriseType ? res.data.enterpriseType : [];
      this.form.messageRemark = res.data.messageRemark;
      this.form.remark = res.data.remark;
      this.form.merchantList = res.data.merchantModelList;

      this.rule = res.data.ruleValue

    },
    async handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if(!this.rule) {
            this.$message.error("积分额度为空");
            return;
          }
          if(this.$route.query.id) {
            this.form.id = this.$route.query.id;
            const res = await api.pointsUpdate(this.form);
            if (res.code === "200") {
              this.$message.success("提交成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            const res = await api.pointsAdd(this.form);
            if (res.code === "200") {
              this.$message.success("提交成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          }

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 项目列表
    async projectNameList() {
      const res = await api.projectName();
      if (res.code == 200) {
        this.projectList = res.data.items;
      }
    },

    // 企业类型
    async getEnterpriseTypeList() {
      const res = await getEnterpriseTypes();
      const { code, data } = res
      if (code === '200') {
        this.enterpriseTypeList = data;
      }
    },

    // 根据积分规则查询积分额度
    async onBlur() {
      var data = {};
      if (this.form.ruleNo) {
        data.ruleNo = this.form.ruleNo
        const res = await api.pointsRule(data);
        this.rule = res.data;
      }
    },

    //上传
    handleChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} 上传成功`);
        this.form.merchantList = info.file.response.data;
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败.`);
      }
    },

    // 删除标签
    tagRemove(index) {
      this.form.merchantList.splice(index, 1)
      // this.form.merchantList = tags;
    }
  },
};
</script>

<style scoped lang="scss">
.indexClass {
  width: 330px;
}

.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

::v-deep .ant-form-item {
  display: flex;
  // margin-bottom: 0;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.content {
  /* margin-left:24px; */
  margin-top: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}

::v-deep .ant-checkbox-group {
  width: 100%;
  .ant-checkbox-group-item {
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
::v-deep #editer {
  tr {
    height: 28.5px;
  }
}
::v-deep .ant-table-thead {
  th {
    &:before {
      content: "*";
      color: red;
    }
  }

  th:nth-child(4) {
    &:before {
      content: "";
    }
  }
}
::v-deep .ant-select-dropdown {
  z-index: 99999;
}
::v-deep .ant-modal-close-x {
  margin: -13px -13px 0 0;
}
.upImg {
  width: 109px;
  height: 109px;
}

.informationBox > div {
  margin-top: 40px;
}
.informationBox > div:nth-child(1) {
  margin-top: 0px;
}
.tip {
  font-size: 12px;
  line-height: 20px;
}
</style>
